import React from "react";

import img1 from "../assets/img/products/img1.png";
import img2 from "../assets/img/products/img2.png";
// import img3 from "../assets/img/products/img3.png";
// import img4 from "../assets/img/products/img4.png";
// import testandgo from '../assets/img/testandgo.png'
import artificially from '../assets/img/artificially.png'
import socialmagic from '../assets/img/socialmagic.png'
import patient from '../assets/img/patient.png'
import aitravel from '../assets/img/aitravel.png'

const products = [
  // {
  //   title: "Covid19 Tracker",
  //   des: "Covid-19 is a React project developed to provide support to people suffering from and affected by Covid-19 virus worldwide. The app is designed to be very simple and light weight while maintaining proper responsiveness on different platforms like web and mobile. It provides statistics from around the world and lets users track the growth of active cases in their country.",
  //   tech: `React, Chart.js, JavaScript, CSS, Bootstrap,
  //   Material UI, JSON, REST APIS`,
  //   src: img1,
  //   gitlink: 'https://github.com/Ishazaka/Covid19-Tracker',
  //   livelink: 'https://covid19effects.netlify.app/'
  // },

  {
    title: "AI Travel Agent",
    des: "AI Travel Agent is a AI software for travellers to give them recommendation about the hotels and places to visit where ever they are planning to go. It gives them a full plan using AI according to their trip days, budget and number of travellers .   ",
    src: aitravel,
    tech: ` React, JavaScript, CSS, Material UI, react-router-dom, Tailwind CSS, Gemini AI API, Firebase, google place Api, react google Authentication `,
    gitlink: 'https://github.com/Ishazaka/react-ecommerce',
    livelink: 'https://ai-travell-agent.vercel.app/'
    
   
  },

  {
    title: "Patient Pulse",
    des: "Patient Pulse is a appointment booking software for the patient with the admin panel to accept and reject the appointments. It includes the feature of forms validations and login as a patient, admin dashbaord, paginations and crud operations to store and retrieve data from database ",
    src: patient,
    tech: `Nextjs, React, JavaScript, CSS, Tailwind CSS, React hook form , zod , react icons library , appwrite ,  `,
   // gitlink: 'https://github.com/Ishazaka/react-ecommerce',
    livelink: 'https://patientpulse-software.vercel.app/'
 
   
  },

  {
    title: "Crypto Hunter",
    des: "Cryptocurrency Hunter is a react app that helps people find the best currency to invest in.It plays a significant role in the success of the user's investments in digital currencies.The chart shows the price, marketcap value, profit, and loss for 100 digital currencies.This service allows users to contrast the performance of multiple cryptocurrencies against one another.Besides showing the currencies that are trending in the most recent 24 hours, it also displays data on ups and downs in the past 30 days and the whole year.",
    tech: `React, Chart.js, JavaScript, CSS, Bootstrap, Material UI, JSON, REST APIS`,
    src: img2,
    gitlink: 'https://github.com/Ishazaka/Crypto-Hunter',
    livelink: 'https://hundredcrypto.netlify.app/'
  },
 


  // {
  //   title: "Cloth Store",
  //   des: "Since everyone was shopping online after the Pandamic, I was inspired to build an online clothing store. The idea came to me after some of my friends contacted me about building an online clothing store. I used React and Javascript to build the clothing store.",
  //   src: img3,
  //   tech: ` React, JavaScript, CSS, Material UI, react-router-dom, Html , icons Library`,
  //   gitlink: 'https://github.com/Ishazaka/react-ecommerce',
  //   livelink: 'https://clothapp.netlify.app/'
 
   
  // },
  // {
  //   title: "Artificially AI",
  //   des: "Artificially AI is the website for priniting the t-shirts with the images generated AI model ",
  //   src: artificially,
  //   tech: ` React, JavaScript, CSS, Material UI, react-router-dom, Html , icons Library`,
  //   gitlink: 'https://github.com/Ishazaka/react-ecommerce',
  //   livelink: 'https://artificiallyapp.netlify.app/'
 
   
  // },
  // {
  //   title: "Test And Go",
  //   des: "This is a health related project. It is built for different schools where they can collect data of students health information specially covid-19 data",
  //   tech: `React, Redux, NodeJs, Express , MongoDB , React Bootstrap`,
  //   src: testandgo,
  //   gitlink: 'https://github.com/Ishazaka/testandgonjs',
  //   livelink: 'https://www.testandgo.com/'
  // },
  // {
  //   title: "Social Magic AI",
  //   des: "It is a chrome Extension which helps users to increase their social Media presence using AI, By leveraging OpenAI, It helps users to generate posts and comments in few clicks without wasting alot of time on Linkedin",
  //   tech: `OpenAI,  React, NodeJs, Express , MongoDB , React Bootstrap`,
  //   src: socialmagic,
  //   ChromeXtention: 'https://chrome.google.com/webstore/detail/social-magic-supercharge/aicinfjgiebaoekhdgcgnjdkdhhmmkeb?hl=en-GB&authuser=0',
  //   livelink: 'https://socialmagic.ai/'
  // },

  // {
  //   title: "Resume Builder",
  //   des: "I faced the challenge of writing a quality resume and creating a quality template when I entered the job market. To overcome this issue, I came up with the idea of building my own template. This Resume Builder was built leveraging the power of JavaScript.",
  //   tech: ` JavaScript, CSS, Bootstrap, Html , icons Library`,
  //   src: img4,
  //   gitlink: 'https://github.com/Ishazaka/resume-builder-website',
  //   livelink: 'https://hazelresume.netlify.app/'
  // },
];

const Product = () => {
  return (
    <section className="product-con" id="products">
      <h1 className="pro-head">Projects</h1>
      <p className="pro-des">
        Here you will find some of the personal and clients projects that I
        created with each project containing its own github and live link
      </p>
      {products.map((pro) => {
        return (
          <div className="pro">
            <div className="pro-img-con">
              <img src={pro.src} alt="product" />
            </div>
            <div className="pro-data">
              <h1>{pro.title}</h1>
              <p>{pro.des}</p>
              <span><strong>{`Technologies : `} </strong>{pro.tech}</span>
              <div 
              style={{margin: '10px 0'}}
              >
                {/* <a rel="noreferrer noopener" target='_blank' href={pro.gitlink}>
                  <button className="pro-btn">Github</button>
                </a> */}
                <a rel="noreferrer noopener" target='_blank' href={pro.livelink}>
                  <button className="pro-btn">Live</button>
                </a>


              </div>
            </div>
          </div>
        );
      })}

{/* <div className="pro">
            <div className="pro-img-con">
              <img src={testandgo} alt="product" />
            </div>
            <div className="pro-data">
              <h1>Test And Go</h1>
              <p>This is a health related project. It is built for different schools where they can collect data of students health information specially covid-19 dat,</p>
              <span><strong>{`Technologies : `} </strong>React, Redux, NodeJs, Express , MongoDB , React Bootstrap</span>
              <div 
              style={{margin: '10px 0'}}
              >
   
                <a rel="noreferrer noopener" target='_blank' href="https://www.testandgo.com/">
                  <button className="pro-btn">Live</button>
                </a>


              </div>
            </div>
          </div> */}




          <div className="pro">
            <div className="pro-img-con">
              <img src={socialmagic} alt="product" />
            </div>
            <div className="pro-data">
              <h1>Social Magic AI</h1>
              <p>It is a chrome Extension which helps users to increase their social Media presence using AI, By leveraging OpenAI, It helps users to generate posts and comments in few clicks without wasting alot of time on Linkedin</p>
              <span><strong>{`Technologies : `} </strong>OpenAI,  React, NodeJs, Express , MongoDB , React Bootstrap</span>
              <div 
              style={{margin: '10px 0'}}
              >
                <a rel="noreferrer noopener" target='_blank' href="https://chrome.google.com/webstore/detail/social-magic-supercharge/aicinfjgiebaoekhdgcgnjdkdhhmmkeb?hl=en-GB&authuser=0">
                  <button className="pro-btn">Chrome Extension</button>
                </a>
                <a rel="noreferrer noopener" target='_blank' href="https://socialmagic.ai/">
                  <button className="pro-btn">Live</button>
                </a>


              </div>
            </div>
          </div>

          <div className="pro">
            <div className="pro-img-con">
              <img src={artificially} alt="product" />
            </div>
            <div className="pro-data">
              <h1>Artificially AI</h1>
              <p>Artificially AI is the website for priniting the t-shirts with the images generated AI model </p>
              <span><strong>{`Technologies : `} </strong>React, JavaScript, CSS, Material UI, react-router-dom, Html , icons Library</span>
              <div 
              style={{margin: '10px 0'}}
              >
               
                <a rel="noreferrer noopener" target='_blank' href="https://artificiallyapp.netlify.app/">
                  <button className="pro-btn">Live</button>
                </a>


              </div>
            </div>
          </div>



    </section>
  );
};

export default Product;
